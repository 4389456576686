
import { Vue, Component } from "vue-property-decorator";
import Axios from "axios";
// import { SERVER_API_URL } from "../../config";
import { AppConfig } from '@/types/config';
import HeaderCompnent from "../../views/header.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { triggerRef } from "vue";
import { UUID } from 'uuid-generator-ts';

@Component({ components: { HeaderCompnent, DatePicker } })
export default class Dashborad extends Vue {
  public menu1 = false;
  public customerList = [];
  public selectedProducts: any = [];
  public menu = false;
  readonly uuid = new UUID();
    public searchTypes = ['Customer Name','Project Name','package ID','License Type','Acc Manager','Product']
  public searchType:any =  'Customer Name'
  public searchString:any = ''
  public customerDetails = {
    id:"",
    customerName: "",
    packageId: "",
    projectName: "",
    licenseCount: 0,
    usedCount: 0,
    createdBy: "",
    unlimited: 0,
    validTime: new Date(),
    accountManager: "",
    createdDate: new Date(),
    licenseType: "",
    product: "",
    comments: "",
  };
  public customerData: any = [];
  public PRODUCTS: any = [];
  public store: any = null;
  public licenseTypes = ["DEV", "POC", "UAT", "PROD"];
  public saveAndEdit = false;
  public managerList: any = [];
  public customerName :any = []
  public dialog = false;
  public packageId: any = null;
  public confirmationDialog = false;
  public licenseDialog = false;
  public licenseString: any = null;
  public error = {
    customerName: [(v: any) => !!v || "Customer Name is mandatory"],
    projectName: [(v: any) => !!v || "Project Name is mandatory"],
    packageId: [(v: any) => !!v || "Package Id is mandatory"],
    product: [(v: any) => !!v || "Product is mandatory"],
    licenseCount: [(v: any) => !!v || "License count is mandatory"],
    validTime: [(v: any) => !!v || "Valid time is mandatory"],
    createdBy: [(v: any) => !!v || "Created By  is mandatory"],
    accountManager: [(v: any) => !!v || "Account Manager  is mandatory"],
    createdDate: [(v: any) => !!v || "CreatedDate  is mandatory"],
    licenseType: [(v: any) => !!v || "License Type is mandatory"],
  };
  custName: any;
  accountManager: any;

      // Directly access the SERVER_API_URL from the Vue prototype
      get SERVER_API_URL(): string {
    return (this.$config as AppConfig).SERVER_ADDRESS;
  }

  // Access NODE_ENV similarly
  get NODE_ENV(): string {
    return (this.$config as AppConfig).NODE_ENV;
  }

  formattingOfDates() {
    return (
      new Date().getDate() +
      "-" +
      (new Date().getMonth() + 1) +
      "-" +
      new Date().getFullYear()
    );
  }
  async getCustomerList() {
    try {
      let fieldName =  this.searchType == 'Customer Name' ? 'customerName' : this.searchType == 'Project Name' ? 'projectName'
       : this.searchType == 'package ID' ? 'packageId' : this.searchType == 'License Type' ? 'licenseType' : this.searchType == 'Acc Manager' ?
       'accountManager' : this.searchType == 'Product' ? 'product' : '';
      let response = await Axios.get(
        `${this.SERVER_API_URL}/list/`,
        { 
          params : {
          type: this.store.userType,
          createdBy: this.store.email, 
        }}
      );
      this.customerList = response.data.customer;
    } catch (error) {
      console.log(error);
    }
  }
 
  setEditObject(data: any) {
    this.customerDetails = JSON.parse(JSON.stringify(data));
    this.customerDetails.validTime = new Date(this.customerDetails.validTime);
    this.selectedProducts = this.customerDetails.product.split(', ');
    this.dialog = true;
    this.saveAndEdit = true;
  }
  closeDialog() {
    this.customerDetails = this.emptyObject();
    this.dialog = false;
    this.saveAndEdit = false;

    (this.$refs["form"] as any).reset();
  }
  openDialog()
  { 
    this.initializeValues();
    this.dialog = true;
  }
  emptyObject() {
    return {
      id: "",
      customerName: "",
      projectName: "",
      packageId: "",
      licenseCount: 0,
      usedCount: 0,
      createdBy: "",
      unlimited: 0,
      validTime: new Date(),
      accountManager: "",
      createdDate: new Date(),
      licenseType: "",
      product: "",
      comments: "",
    };
  }

  async updateCustomer() {
    try {
      this.customerDetails.unlimited = this.customerDetails.unlimited ? 1 : 0;
      this.customerDetails.product = this.selectedProducts.join(', ');
      this.customerDetails.validTime = new Date(this.formattedDate(this.customerDetails.validTime));
      let response = await Axios.put(`${this.SERVER_API_URL}/updateCustomer`,  this.customerDetails
      );
      if (response.status == 200 || response.status == 201) {
        this.$snotify[response.status == 201 ? 'error':'success'](response.data.id != null ? "Project updated successfully!" : response.data);
        if (response.status == 200) {
          await this.getCustomerList();
        }
        this.saveAndEdit = false;
        this.dialog = false;
        (this.$refs["form"] as any).reset();
        this.customerDetails = this.emptyObject();
      }
    } catch (error) {
      console.log(error);
    }
  }
  async saveCustomer() {
    try {
      if (!(this.$refs["form"] as any).validate()) return;
      this.customerDetails.createdBy = this.store.email;
      this.customerDetails.packageId = this.customerDetails.packageId.trim();
      this.customerDetails.id = this.uuid.getDashFreeUUID();
      this.customerDetails.unlimited = this.customerDetails.unlimited ? 1 : 0;
      this.customerDetails.product = this.selectedProducts.join(", ");
      this.customerDetails.validTime = new Date(this.formattedDate(this.customerDetails.validTime));

      let response = await Axios.post(`${this.SERVER_API_URL}/saveCustomer`, this.customerDetails);
      if (response.status == 200 || response.status == 201) {
        this.$snotify[response.status == 201 ? 'error':'success'](response.data.id != null ? "Project Added Successfully!" : response.data);

        if (response.status == 200) {
          this.customerDetails = this.emptyObject();
          (this.$refs["form"] as any).reset();
          this.dialog = false;
          await this.getCustomerList();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  formattedDate(validTime: any) {
      if (!validTime) return '';

      const dateObj = new Date(validTime);

      // Extract year, month, and day from the Date object
      const year = dateObj.getFullYear(); // Get full year (e.g., 2024)
      const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Get month (add 1 because getMonth() is zero-based)
      const day = dateObj.getDate().toString().padStart(2, '0'); // Get day

      // Format into yyyy-MM-dd format
      return `${year}-${month}-${day}`;
    }
  async deleteCustomer() {
    try {
      let response = await Axios.delete(
        `${this.SERVER_API_URL}/deleteCustomer/${this.packageId}`
      );
      this.$snotify.success(response.data.message);
      this.confirmationDialog = false;
      await this.getCustomerList();
    } catch (error) {
      console.log(error);
    }
  }
 async getManagerList() {
    try {
      let response = await Axios.get(`${this.SERVER_API_URL}/Accountmanagers`);
      if (response.data.length > 0) {
        response.data.forEach((p: any) => {
          this.managerList.push(p.name);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  changeAccountManagerValues() {
    
      let manager = this.customerData.find((p: any) => p.customerName.toString() == this.customerDetails.customerName.toString()) 
      this.customerDetails.accountManager = manager.accountManager
  }
  async customerLists() {
    try {
      let response = await Axios.get(`${this.SERVER_API_URL}/Customerdetails`);
      if (response.data.length > 0) {
        this.customerData = response.data
        response.data.forEach((p: any) => {
          this.customerName.push(p.customerName);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  async getAppLicenseId(packageId: any,ul :any) {
    try {
      let parameter = ul == 1 ? 'unlimited' : Math.random()
      let response = await Axios.get(
        `${this.SERVER_API_URL}/license/${packageId}/${parameter}`
      );
      if (response.data) {
        this.licenseDialog = true;
        if (response.status == 201)
          this.licenseString =
            "This Package id does not have unlimited service . ";
        else if (response.status == 200)
          this.licenseString = `${response.data}`;
      }
    } catch (error) {
      console.log(error);
    }
  }
  copyToClipboard() {
    var copyText:any = document.getElementById("licenseString");
    copyText.focus();
    copyText.select();
    document.execCommand("copy")
    this.$snotify.success('Copied ! ...')
    
  } 
  checkExipryDate(data :any) {  
     if( new Date() >  new Date(data.validTime)) return 'expiryClassRed'
     else return 'expiryClassWhite'

  }
  openConfirmationDailog(appId: any) {
    this.packageId = appId;
    this.confirmationDialog = true;
  }

  async mounted() {
     await this.initializeValues();
  }
   async initializeValues()
  {
    this.store = JSON.parse(localStorage.getItem("UserInfo") || "");
    this.customerDetails = this.emptyObject();
    if(this.isAdmin())
    {
    await this.customerLists();
    await this.getCustomerList();
    await this.getManagerList();
    await this.getProducts();
    }
    else
    {
      console.log("Its not admin");
      await this.getManagerList();
      await this.getCustomerList();
      this.customerDetails.customerName = this.store.customerName;
      this.custName = this.store.customerName;
      this.customerDetails.accountManager = this.store.accountManager;
      this.PRODUCTS = this.store.product.split(', ');
    }
   
  }
  async getProducts() {
      try {
        const response = await Axios.get(`${this.SERVER_API_URL}/products`); // Replace with your API endpoint
        if (response.data) {
          this.PRODUCTS = response.data.map((e: any) => ({
            ...e,
            nameVersion: `${e.name} ${e.version}`
          }));
        }
      } catch (error) {
        console.error('Failed to get data', error);
      }
    }

  isAdmin() {
      // Check if the userType is admin
      return this.store && this.store.userType === "Admin";
    }
}
